import { createGetKcContext } from 'keycloakify/login';

// WARNING: It's important to keep in sync the extraThemeProperties declared in the package.json and this type definition.
export type KcContextExtension =
    | { pageId: 'denied-auth.ftl' }
    | { pageId: 'login.ftl'; extraThemeProperties: { foo: string } }
    // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
    | { pageId: 'register.ftl'; authorizedMailDomains: string[] };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page
export const { getKcContext } = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            pageId: 'login.ftl',
            locale: {
                currentLanguageTag: 'en',
            },
            //Uncomment the following line for hiding the Alert message
            //"message": undefined
            //Uncomment the following line for showing an Error message
            //message: { type: "error", summary: "This is an error" }
        },
        {
            pageId: 'register.ftl',
            authorizedMailDomains: [
                'example.com',
                'another-example.com',
                '*.yet-another-example.com',
                '*.example.com',
                'hello-world.com',
            ],
            // Simulate we got an error with the email field
            messagesPerField: {
                printIfExists: <T>(fieldName: string, className: T) => {
                    console.log({ fieldName });
                    return fieldName === 'email' ? className : undefined;
                },
                existsError: (fieldName: string) => fieldName === 'email',
                get: (fieldName: string) => `Fake error for ${fieldName}`,
                exists: (fieldName: string) => fieldName === 'email',
            },
        },
    ],
});

export const { kcContext } = getKcContext({
    // Uncomment to test the login page for development.
    // mockPageId: 'login.ftl',
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>;
