import { createGetKcContext } from 'keycloakify/account';

import type { TotpConfigType } from '../../components/TotpConfig';

export type KcContextExtension =
    | { pageId: 'my-extra-page-2.ftl'; someCustomValue: string }
    | {
          pageId: 'totp.ftl';
          stateChecker: string;
          mode?: 'qr' | 'manual' | undefined | null;
          totp: TotpConfigType;
          url: {
              totpUrl: string;
          };
      };

export const { getKcContext } = createGetKcContext<KcContextExtension>({
    mockData: [
        {
            pageId: 'my-extra-page-2.ftl',
            someCustomValue: 'foo bar',
        },
        {
            pageId: 'totp.ftl',
            totp: {
                enabled: true,
            },
        },
    ],
});

export const { kcContext } = getKcContext({
    //mockPageId: "password.ftl",
});

export type KcContext = NonNullable<ReturnType<typeof getKcContext>['kcContext']>;
